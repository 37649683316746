.sidbar_container{
  padding-left:20px;
  font-size: 18px;
  line-height: 25px;
  font-weight:bold;
}
.sidbar_container h3{
  color:#f6f9f6;
}
.link_box{
  border:#394e39;
  margin-top:4px;
  padding:3px;
  border-radius: 4px;
  border:1px solid #c5c9c3;
  background-color: rgb(243, 249, 249);
}
.cart-items {
  list-style: none;
  margin: 0;
  padding: 0;
  max-height: 20rem;
  overflow: auto;
}

.total {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
  font-size: 1.5rem;
  margin: 1rem 0;
}

.actions {
  text-align: right;
}

.actions button {
  font: inherit;
  cursor: pointer;
  background-color: transparent;
  border: 1px solid #fff;
  padding: 0.5rem 2rem;
  border-radius: 25px;
  margin-left: 1rem;
}

.actions button:hover,
.actions button:active {
  background-color: #236dc1;
  border-color: #fff;
  color: white;
}

.actions .button--alt {
  color: #fff;
}

.actions .button {
  background-color: #8a2b06;
  color: white;
}
