.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 20;
  background-color: rgba(0, 0, 0, 0.219);
}

.modal {
  position: fixed;
  /* top: 5vh; */
  top: 0px;
  left: 1%;
  width: 22%;
  height:100%;
  background: #006CD9;
  background: linear-gradient(188deg, rgb(6, 98, 228) 0%, rgb(32, 69, 132) 50%, rgb(10, 16, 23) 100%);
  
  padding: 1rem;
  border-radius: 0px;
  /*box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);*/
  z-index: 30;
  animation: slide-down 300ms ease-out forwards;
}

@media (max-width: 768px) {
  .modal {
    width: 13rem;
    left: calc(1% - 1rem);
    height: 100%;
  }
}

@keyframes slide-down {
  from {
    opacity: 0;
    transform: translatex(-20rem);
  }
  to {
    opacity: 1;
    transform: translatex(0);
  }
}