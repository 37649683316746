.button {
 
    background-color: #60c564; /* Green */
    border: none;
    color: white;
    padding: 5px 5px;
    text-align: center;
    text-decoration: none;
    display: inline;
    font-size: 16px;
    margin:2px;
    height:30px;
    /* min-width: 100px; */
  }