.header {
  /* position: fixed; */
  top: 0;
  left: 0;
  width: 100%;
  /* height: 5rem; */
  background-color: #c9e5f1;
  color: white;
  display: flex;
  justify-content: flex-end;
  align-items: top;
  padding: 5px 5px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
  z-index: 10;
}
.header_items{
  margin-left: 15px;
  margin-right:10px;
  /* background-color: aquamarine; */
 }
 .icon_size{
  display:flex;
  align-items:flex-start ;
  font-size:31px;
  border:1px solid #fff;
  border-radius:2px;
  /* color:rgb(3, 6, 90); */
 background-color:rgb(12, 56, 111);
 }
.containers{
  margin: auto;
  width:80%;
 
}
.btn{
  padding:3px;
  font-size: 20px;
}

@media only screen and (max-width: 992px) {
  .containers{
    margin: auto;
    width:100%;
  }
}

/* .main-image {
  width: 100%;
  /* height: 25rem; */
/*  height:100px;
  z-index: 0;
  overflow: hidden;
} */

/* .main-image img {
  width: 110%;
  height: 100%;
  object-fit: cover;
  transform: rotateZ(-5deg) translateY(-4rem) translateX(-1rem);
} */
