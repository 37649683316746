body {
  font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  margin: 0;
  font-size: 1rem;
  font-weight: 1.5;
  line-height: 1.5;
  color: #292b2c;
  background-color: #aab1b7;
  background-color: #d8e0e8;
}


*{
  box-sizing: border-box;
}
.container_main{

  width:100%;
  height:100%;
 
  display:flex;
  flex-direction:column;
  /*justify-content:space-around; */
  justify-content:flex-start;
  
}



  .left{
      width:80%;
  
  }
  .right{
      display:flex;
      direction:column;
      width:20%;
      
  }

  
  .active{
    background-color: #4CAF50;
    color: #fff;
  }

 




.header {
  width: 100%;
  height: 5rem;
  background-color: #044599;
  padding: 0 10%;
}

.header nav {
  height: 100%;
}

.navi{
  display: flex;
 
  
  width:100%;
  height:50px;
/*  background-color: #eae6e3;
  background-color: #4CAF50;*/
  
}
.navi__btn{
  background-color: #4CAF50; /* Green */
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin-right:1px;

}


.header ul {
  height: 100%;
  list-style: none;
  display: flex;
  padding: 0;
  margin: 0;
  align-items: center;
  justify-content: center;
}

.header li {
  margin: 0 1rem;
  width: 5rem;
}

.header a {
  color: white;
  text-decoration: none;
}

.header a:hover,
.header a:active,
.header a.active {
  color: #95bcf0;
  padding-bottom: 0.25rem;
  border-bottom: 4px solid #95bcf0;
}

a.active {
  color: #164e97;
  padding-bottom: 0.25rem;
  border-bottom: 4px solid #95bcf0;
}
a:link{text-decoration: none;}

a:hover
 {
  color: #21262c;
  background-color: rgb(126, 145, 145);
  padding-bottom: 0.25rem;

}
a:visited,a:link
 {
  color: #3a4a60;
  padding-bottom: 0.25rem;

}





*{
  box-sizing: border-box;
}

.my__header{

width:100%;
height:200px;
background-color:rgb(211, 48, 48);
border-radius:0px;
display:flex;
align-content: center;
box-sizing: border-box;
padding:10px;

}


h2{

  color:#fff;
}

.btn__box{
  display: flex;
  flex-direction: row;
  width:200xp;
  height:150px;
  background-color: #43423f;
  padding:10px;
}

.btn{
  
  background:#345;
  background-color: #fff;
  padding:5px;
  border-radius: 5px;
  width:200px;
 
  border-radius: 5px;
  box-sizing: border-box;
  margin-right:10px;
}
.btn__stop{
  background-color: #43423f;
  padding:5px;
  border-radius: 5px;
  width:200px;
 
  border-radius: 5px;
  border: 1px solid #fff;
  
  
  }


  /* lastTenwords */


  .container_ltw{

    width:50%;
    background-color: #43423f;
  }

  .record__wapper{
width:50%;
margin-top:20px;
margin-bottom:20px;

  }