.update__form input {

    width: 100%;
    margin-bottom: 20px;
    line-height: 30px;
    box-sizing: border-box;
    border:solid 1px #ccc;
    border-radius: 3px;
}

.button__container{

    display:flex;
    width:100%;
    justify-content: space-between;
}