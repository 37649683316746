.button {
 
    background-color: #256a27; /* Green */
    border: none;
    text-shadow: rgb(49, 39, 39);
    color:#fff;
    padding: 5px 5px;
    text-align: center;
    text-decoration: none;
    display: inline;
    font-size: 16px;
    margin:2px;
    height:30px;
    min-width: 50px;
    border-radius: 2px;
  }