



    .containers{
   
        margin:auto;
        width:80%;
        /*background-color: rgb(152, 198, 92);*/
       
    }

    


    @media only screen and (max-width: 992px) {
.containers{
    width:100%;
}
}