body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.main_container {
  /* padding-left:5px;
  padding-right:5px; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.color-black {
  color: #222;
}
/*input [type="checkbox"]{
  background-color: #c00;
  color:#0c0;
  height:50px;
}
https://stackoverflow.com/questions/1120879/css-checkbox-input-styling
*/

* {
  box-sizing: border-box;
}

#page-wrap {
  padding-bottom: 10px;
  padding-top: 10px;
}

/**
* container
**/
.container {
  margin: 0 auto;
  width: 80%;
  /*background-color: rgb(145, 167, 187);*/
  /* background: rgb(6,228,97);
background: linear-gradient(188deg, rgba(6,228,97,1) 0%, rgba(32,132,66,1) 50%, rgba(10,23,13,1) 100%); */
background-color: #006CD9;
min-height: 100vh;
}
@media only screen and (max-width: 1200px) {
  .container {
    width: 1020px;
  }
}

@media only screen and (max-width: 992px) {
  .container {
    margin: auto 0;
    width: 95%;
  }
  .left {
    width: 75%;
  }
  .right {
    display: flex;
    direction: column;
    width: 15%;
  }
}

@media only screen and (max-width: 768px) {
  .container {
    margin: auto 0;
    width: 100%;
  }
  .left {
    width: 60%;
  }
  .right {
    display: flex;
    direction: column;
    width: 20%;
  }
}

@media only screen and (max-width: 576px) {
  .container {
    width: 100%;
  }
}

/****** container end *********/

.elign__element__right {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-end;
  width: 80%;
  margin: auto;
  color: #fff;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-right: 10px;
}
@media only screen and (max-width: 992px) {
  .elign__element__right {
    justify-content: center;
    width: 100%;
  }
}
.bg__color {
  background-color: #368571 ;
  background: rgb(6,228,97);
  background: linear-gradient(188deg, rgba(6,228,97,1) 0%, rgba(32,132,66,1) 50%, rgba(10,23,13,1) 100%);
  background:#1A274F;
  background-color:#006CD9;
}
.bg__color h3 {
  color: #fefefe;
  height: 20px;
}

.flex-items {
  font-size: 20px;
  margin-left: 20px;
}

/* form elements mormation */
select {
  /* width: 100%; */
  /* padding: 16px 20px; */
  /* border: none; */
  border-radius: 2px;
  font-size: 17px;
  font-weight: bold;
  background-color: #fcfcfb;
}

input[type="text"] {
  margin-top: 5px;

  font-size: 17px;

  color: #000 !important;
}
.tb1 {
  color: #000 !important;
  font-size: 18px;
  font-weight: bold;
  /* background-color: yellow; */
}

.tb1:focus {
  /* background-color : #d0e119; */

  color: #000;
  /* border-style: none; */
}
input:focus {
  color: #000;
}

input:disabled {
  color: black;
}

.add_button {
  background-color: rgb(246, 17, 17);
  border: 1px solid #fefefe;
  border-radius: 3px;
  /* padding:5px; */
  font-size: 18px;
  font-weight: bold;
  color: #fff;
}
.header_button {
  /* background-color: #368571; */
  background-color: #f91a12;
  /* background-color: #e9b90d; */
  border: 1px solid #fefefe;
  padding: 5px;
  font-size: 18px;
  font-weight: bold;
  color: #fff;
  /* color: rgb(8, 8, 8); */
  border-radius: 3px;/*16*/
  min-width:60px;
/*  box-shadow: 3px 3px #fff;*/
  /* -webkit-box-shadow: inset -1px 3px 5px 3px #9dadbf, 2px 5px 16px 0px #0B325E, 5px 5px 15px 5px rgba(0,0,0,0); 
box-shadow: inset -1px 3px 5px 3px #9dadbf, 2px 5px 16px 0px #0B325E, 5px 5px 15px 5px rgba(0,0,0,0); */
}

.header_button_pressed {
 
  background-color: #d0d4d3;
  background-color: #60C564;
  background-color: #006CD9;
  border: 1px solid #fefefe;
  padding: 5px;
  font-size: 18px;
  font-weight: bold;
  color: rgb(81, 80, 80);
  color:#fff;
  /* color:#111; */
  border-radius: 3px;/*16 */
  /* text-shadow: 1px 1px #fff; */
  min-width:60px;
  
}
/* title bar */

.title_bar{
  
  background-color: #03155e;
  text-align: center;
  color:#60C564;
  padding-left:20px;
  font-weight: bold;
  font-size: 20px;
}


/**
* Forms
**/

/* input [type="submit"]{
  background-color: #368571;
} */


/* card */

.card {
  width: 90vh;
  min-height: 80vh;
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  align-self: flex-start !important;

  /*background-color:rgb(191, 187, 193);*/
  border: 1px solid rgb(165, 165, 163);
  border-radius: 10px;
  background-color: rgb(113, 111, 111);
  background-color: #938E93;
}

.card_text{
  color:#fff;
  font-size:25px;
}
.card_h1{
  color:#fff;
  font-size:30px;
  font-weight: normal;
}

/* Tables */

table{
  width:100%;
  background-color: #eee;
  border: 1px;
  font-weight: 500;
  color:#000;
}

/* table, th, td {
  border: 0px solid;
}
th{
  text-align: left;
} */

tr{
  background-color: rgb(121, 228, 176);
  background-color: #6e226e;
  background-color: #F7DB02;
  background-color: #0d0e13cc;
}
th{
  color:blueviolet;
  color:#fff;
 
}
td{
background-color:rgb(245, 246, 242); 
/* padding-left:5px; */
text-align: center;
}



.high_light {
  background-color: #cfded018;
  border: 1px solid #caf4e9;
  border-radius: 3px;
  color: rgb(215, 12, 12);
}


/* button */

.save_btn {
  background-color: #f40505;
  border: 1px solid #fcfcfb;
  color: #fff;
  font-weight: bold;
  padding: 5px;
  min-width: 100px;
  font-size: 20px;
  margin-top:50px;
  border-radius: 20px;
}


.btn_width{
  width:150px;
}


/* data table */

.red{
  color:#eb2020;
}