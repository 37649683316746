



/*input */
.input {
    margin: 20px auto;
    padding: 1rem;
    width: 90%;
    max-width: 40rem;
  }
  
  .label {
    display: block;
    font-weight: bold;
    margin-bottom: 0.5rem;
  }
  
  .input input {
    font: inherit;
    display: block;
    width: 100%;
    border: 1px solid #ccc;
    padding: 0.15rem;
    margin-bottom: 0.5rem;
  }
  
  .input input:focus {
    outline: none;
    border-color: #4f005f;
  }
  
  /* button */
  
  .btn{
      background-color: #4CAF50; /* Green */
      border: none;
      color: white;
      padding: 15px 32px;
      text-align: center;
      text-decoration: none;
      display: inline-block;
      font-size: 16px;
  
  }
  
  .button {
      font: inherit;
      border: 1px solid #4f005f;
      margin-right:1px;/* i added it */
      background: #4f005f;
      color: white;
      padding: 0.25rem 1rem;
      cursor: pointer;
    }
    
    .button:hover,
    .button:active {
      background: #741188;
      border-color: #741188;
    }
    
    .button:focus {
      outline: none;
    }